<template>
  <div class="md:mx-8 md:mb-4">
    <PaperLogo :name="paper.Name" />
    <template v-if="userStore.isLoggedIn">
      <h2 class="text-3xl font-bold font-sans text-center leading-9">
        {{ atsReadMediaName(paper.Name ?? '') }}
      </h2>
      <div class="text-3xl font-sans font-light text-center leading-9">
        {{ $t('trialSignup.14DayFreeTrial') }}
      </div>
      <p class="p-5 pb-0 mx-auto">
        <i18n-t keypath="trialSignup.addPaperInfo" tag="span" scope="global">
          <template #site>{{ config.public.site.name }}</template>
        </i18n-t>
      </p>
    </template>
    <template v-else>
      <h2 class="text-3xl font-bold font-sans text-center leading-9">
        {{ atsReadMediaName(paper.Name ?? '') }}
      </h2>
      <div class="text-3xl font-sans font-light text-center leading-9">
        {{ $t('trialSignup.14DayFreeTrial') }}
      </div>
    </template>

    <div class="px-5 pb-2 pt-0 text-left">
      <ul class="list-none list-inside mt-5">
        <li
          v-for="(youGet, index) in $tm('trialSignup.youGetList')"
          :key="index"
        >
          {{ $rt(youGet) }}
        </li>
      </ul>
    </div>

    <div class="mt-5 text-center">
      <button
        class="ml-2 text-white article-label !font-medium py-4 px-6"
        :class="
          true
            ? 'rounded-md bg-black transform translate-y-px'
            : 'rounded-full bg-blue'
        "
        data-testid="start-trial-button"
        @click="onClick"
      >
        {{
          userStore.isLoggedIn
            ? $t('trialSignup.buttonStartTrial')
            : $t('trialSignup.buttonCreateUserAndStartTrial')
        }}
      </button>
    </div>

    <div class="captions mt-5 mr-auto ml-auto w-8/12">
      {{ $t('trialSignup.disclaimer') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ContentPaper } from '~/typesManual/content_api/paper'

const config = useRuntimeConfig()

const dataLayer = useDataLayer()
const userStore = useUserStore()

defineProps<{
  paper: ContentPaper
}>()

const emit = defineEmits(['clicked-start-trial'])
const onClick = () => {
  dataLayer.trial.startTrialClick()
  emit('clicked-start-trial')
}
</script>

<style scoped>
.captions {
  opacity: 0.5;
}
li {
  @apply py-1;
  background: no-repeat url('@/assets/icons/checkmark.svg?url') left center;
  padding-left: 35px;
}
</style>
