<template>
  <div
    class="flex items-center w-full mt-2 mb-8 px-4 py-2 rounded-full border border-gray-300"
  >
    <IconSearch
      class="w-5 h-5 transition-all duration-200 fill-current hover:text-blue cursor-pointer"
      @click="onSearch"
    />
    <input
      v-model="searchWords"
      type="text"
      class="article-label outline-none bg-white transition-all duration-300 py-2 ml-2"
      :placeholder="placeholder"
      @keyup.enter="onSearch"
    />
  </div>
</template>

<script setup lang="ts">
import IconSearch from '~/assets/icons/search.svg?component'

const sagaPaperStore = useSagaPaperStore()

const searchWords = ref('')

defineProps<{
  placeholder: string
}>()
// Methods
const onSearch = async () => {
  await navigateTo({
    name:
      sagaPaperStore.isFrontPagePaper || true
        ? 'searchwords'
        : 'paper_searchwords',
    params: {
      words: searchWords.value,
      paper:
        sagaPaperStore.isFrontPagePaper || true
          ? undefined
          : sagaPaperStore.activePaper.slug,
    },
  })
}
</script>
