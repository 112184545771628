<template>
  <div
    class="left-0 bottom-0 w-full fixed transform-gpu transition-all duration-300 opacity-0 pointer-events-none top-[63px] md:top-[149px]"
    :class="{
      'opacity-100 z-[1000]': open,
    }"
  >
    <div
      class="pointer-events-auto fixed h-screen w-screen bg-opacity-10 bg-gray-700 right-0"
      :class="{ hidden: !open }"
      @click="$emit('closeMenu')"
    ></div>

    <div
      class="absolute overscroll-y-none md:overscroll-y-auto w-screen md:w-[400px] h-dvh left-0 z-[1001] bg-white pb-4 pt-8 px-4 md:px-8 overflow-y-scroll transform transition-all -translate-x-full duration-300 pointer-events-auto scrollbar-hide"
      :class="{
        'translate-x-0 ': open,
      }"
      @click.stop
    >
      <LayoutSearch placeholder="Søg på MM" />

      <div class="flex flex-col overflow-y-auto scrollbar-hide h-dvh">
        <nav v-for="(item, i) in focusAreas" :key="i" class="">
          <p class="text-[15px] font-mm-serif -mb-3 text-gray-600">
            {{ item.headline }}
          </p>
          <CommonDivider />
          <ul>
            <li
              v-for="(subitem, subItemIndex) in item.submenu"
              :key="'submenu' + subItemIndex"
              class="[&:not(:last-child)]:mb-2"
            >
              <NuxtLink
                :to="{ name: 'paper', params: { paper: subitem.link } }"
                class="font-mm-serif text-[18px] pointer-events-auto"
                @click="emit('closeMenu')"
              >
                {{ subitem.headline }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
        <nav v-for="(item, i) in menu2" :key="i" class="mt-6">
          <p class="text-[15px] font-mm-serif -mb-3 text-gray-600">
            {{ item.headline }}
          </p>
          <CommonDivider />
          <ul>
            <li
              v-for="(subitem, subItemIndex) in item.submenu"
              :key="'submenu' + subItemIndex"
            >
              <NuxtLink
                v-if="subitem.link.indexOf('https') == -1"
                :to="subitem.link"
                class="font-serif text-[15px] inline-block"
              >
                {{ subitem.headline }}
              </NuxtLink>
              <a
                v-else
                :href="subitem.link"
                class="font-serif text-[15px] inline-block"
              >
                {{ subitem.headline }}
              </a>
            </li>
          </ul>
        </nav>
        <NuxtLink
          v-if="!userStore.user"
          :to="{ name: 'login' }"
          class="font-serif text-[15px] inline-block"
        >
          Log ind
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()
const userStore = useUserStore()
import type { Header } from '~/typesManual/menu'
const emit = defineEmits(['closeMenu'])

withDefaults(
  defineProps<{
    open: boolean
  }>(),
  {
    open: false,
  }
)

const focusAreas = computed(() => {
  return indexStore.menu?.header.filter(
    (item: Header) => item.priority > 99 && item.priority < 199
  )
})
const menu2 = computed(() => {
  return indexStore.menu?.header.filter(
    (item: Header) => item.priority > 199 && item.priority < 399
  )
})
</script>
