<template>
  <ul
    class="px-5 text-center border-y border-gray-300 pointer-events-auto transform-gpu transition-all duration-300 mt-0 w-full"
  >
    <li
      v-for="(paper, i) in sortedPapers"
      :key="i"
      class="py-3 mx-10 inline-block"
      :class="{
        'border-b-2 border-blue ': isActiveMenuItem(paper),
      }"
      :style="{
        borderColor: isActiveMenuItem(paper)
          ? getMmPaperColor(paper.slug)
          : undefined,
      }"
    >
      <NuxtLink
        ref="paperLinks"
        class="font-mm-serif uppercase text-[12px] md:text-[16px]"
        :to="{ name: 'paper', params: { paper: paper.slug } }"
        >{{ paper.name }}
      </NuxtLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { NormalizedPaperSagaDto } from '~/typesManual/normalized'

const sagaPaperStore = useSagaPaperStore()

const desiredOrder = [7, 10, 9, 8]
const sortedPapers = computed(() => {
  return [...sagaPaperStore.nichePapers].sort((a, b) => {
    return desiredOrder.indexOf(a.id ?? -1) - desiredOrder.indexOf(b.id ?? -1)
  })
})

const isActiveMenuItem = (paper: NormalizedPaperSagaDto) => {
  return sagaPaperStore.activePaper.slug === paper.slug
}
</script>
